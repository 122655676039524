import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import FullText from '../../components/full-text';
import HalfImageText from '../../components/half-image-text';
import MediaWithText from '../../components/media-with-text';
import TicketList from '../../components/ticket-list';
import { ProgramModel, ProgramModelCourse, ProgramModelItem } from '../../models/global';
import { useStore } from '../../store/store';
import "./program-detail.scss"
import { formatNumber } from '../../actions/utils';

const ProgramDetailPage = () => {
    let { slug } = useParams();
    
    const stringResources = useStore(state => state.stringResources);
    const programs = useStore(state => state.programs);

    const [program, setProgram] = useState<ProgramModel>();

    useEffect(() => {
        const programData = programs.find((item: ProgramModel) => {
            return item.slug == slug;
        });

        if(programData)
        {
            setProgram(programData);
        }
    }, [programs]);
    
    return (
        <>
            <MetaTags>
                <title>Özgünlük Okulu | Program</title>
                <meta name="title" content="Özgünlük Okulu" />
                <meta name="description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:title" content="Özgünlük Okulu" />
                <meta property="og:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
                <meta property="og:image" content="https://ozgunlukokulu.com/social-image.png" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Özgünlük Okulu" />
                <meta name="twitter:description" content="Özgünlük Okulu, Kundalini Yoga ve Meditasyon öğretisini temel alarak ve pek çok farklı disiplini, spiritüel yaklaşımı bu kadim öğretiyle harmanlayarak hazırladığımız programlarla, seanslarla sıradanın mükemmelliğini deneyimlemen için açtığımız kollektif bir alan." />
            </MetaTags>

            {program &&
                <>
                    <MediaWithText
                        backgroundColor={program.backgroundColor}
                        image={program?.image}
                        title={(!!program.title ? "<p>" + program.title + "</p>" : undefined)}
                        subtitle={(!!program.subtitle ? "<p>" + program.subtitle + "</p>" : undefined)}
                        text={program.description}
                    />
                    {program.courses && program.courses.length > 0 &&
                        <TicketList
                            title={stringResources["Web.ProgramDetail.Courses"]}
                            allPackagePrice={program.price ? "<p>" + program.price + "</p>" : ""}
                            allPackageUrl={program.purchaseLink}
                            items={program.courses.sort((a, b) => {
                                    if(a.isPast && !b.isPast) {
                                        return 1;
                                    }
                                    else if(!a.isPast && b.isPast) {
                                        return -1;
                                    }

                                    if(!a.date) {
                                        return 1;
                                    }
                                    else if(!b.date) {
                                        return -1
                                    }
                                    else if(a.date > b.date) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }).map((course: ProgramModelCourse) => {
                                return {
                                    dateDay: course.date ? moment(course.date).format('D') : undefined,
                                    dateMonth: course.date ? moment(course.date).format('MMMM').substring(0, 3) : undefined,
                                    name: course.title,
                                    description: course.description,
                                    program: program.title,
                                    instructor: !!course.instructor ? course.instructor : program.instructor,
                                    place: course.location,
                                    price: course.price,
                                    buyUrl: course.purchaseLink,
                                    isPast: course.isPast
                                }
                            })}
                        />
                    }
                    {!!program.infoText &&
                        <FullText
                            backgroundColor={program.infoBackgroundColor}
                            title={(!!program.infoTitle ? "<p>" + program.infoTitle + "</p>" : undefined)}
                            text={program.infoText}
                        />
                    }
                    {program.items.map((item: ProgramModelItem, index: number) => {
                        return (
                            <HalfImageText
                                backgroundColor={item.backgroundColor}
                                image={item.image}
                                title={item.title}
                                text={item.text}
                                isDark={item.isDark}
                                isImageLeft={index % 2 == 0 ? true : false}
                            />
                        )
                    })}
                </>
            }
        </>
    )
}

export default ProgramDetailPage;